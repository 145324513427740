import React from 'react';
import { FaEnvelopeOpenText, FaLink, FaLinkedin, FaRegIdCard, FaWhatsapp } from 'react-icons/fa';
import './App.css';

let mobileNumber = "5511976542059"
let mobileNumberFmt = mobileNumber.replace(/(\d{2})(\d{2})(\d{1})(\d{2})(\d{2})(\d{4})/, "($2) $3 $4$5-$6")
let walink = `https://api.whatsapp.com/send?phone=${mobileNumber}&text=Olá! Gostaria de marcar uma consulta.`
let linkedinLink = "https://www.linkedin.com/in/lilian-la-gamba-1a7636b/"
let profilePicture = "1539542469598.jpg"
let crplink = "http://www.cadastro.cfp.org.br/visualizar.html?profissional=s3hRoHJ28AJg%2BBMw0YU%2BF5HNyYX2OQSnGkhzrJDnNa4%3D"
let linkCalendly = "https://calendly.com/li-lagamba/psicoterapiacomlilian"
let personName = "Lilian La Gamba"
let webSiteLink = "https://www.lipsicoterapia.com"
let email = "li.lagamba@gmail.com"
let personCrp = "06/26996"

// const mobileNumber = "5511988554666"
// const mobileNumberFmt = mobileNumber.replace(/(\d{2})(\d{2})(\d{1})(\d{2})(\d{2})(\d{4})/, "($2) $3 $4$5-$6")
// const walink = `https://api.whatsapp.com/send?phone="${mobileNumber}&text=Olá! Gostaria de marcar uma consulta.`
// const linkedinLink = "https://www.linkedin.com/in/profile/"
// const profilePicture = "1539542469598.jpg"
// const crplink = "http://www.cadastro.cfp.org.br/visualizar.html?profissional=dsldjslkjdslkjdslkjdlskdj"
// const linkCalendly = "https://calendly.com/krateria/terapiacomlilian"
// const personName = "Adonsona Lamber"
// const email = "test@gmail.com"
// const personCrp = "25588"

const { version } = require('../package.json');

const Mailto = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`} target="_blank" rel="noopener noreferrer" className="button">
  <p class="button-content"> <FaEnvelopeOpenText className="linkedin-icon" /> {email}</p>
  
  </a>;
  /* return <a href={`mailto:${email}${params}`}>{children}</a>; */
};

function App() {
  document.title = "Lilian La Gamba"
  return (
    <div className="business-card">
      <div className="frame">
      {/* <div className="logo"></div> */}
        <h1 className="title">{personName}</h1>
        <div className="card-content">
        <div className="profile-picture">
            <img src={profilePicture} alt="Foto do Profissional" className="rounded-image" />
          </div>
          <p><center><b>Psicologa clínica</b></center></p>
          {/* <p>
            <a href={crplink} target="_blank" rel="noopener noreferrer" className="whatsapp-link">
            <strong><FaRegIdCard className="linkedin-icon" /> CRP {personCrp}</strong>
            </a>
          </p> */}
            <p>
            <a href={crplink} target="_blank" rel="noopener noreferrer" className="button"><p class="button-content">
            <FaRegIdCard className="linkedin-icon" /> CRP {personCrp}
            </p>
            </a>
            </p>
          <div className="contact">
            {/* <a href={walink} target="_blank" rel="noopener noreferrer" className="whatsapp-link">
            <strong><FaWhatsapp className="whatsapp-icon" /> {mobileNumberFmt}</strong>
            </a> */}
            <p>
            <a href={walink} target="_blank" rel="noopener noreferrer" className="button"><p class="button-content">
            <FaWhatsapp className="linkedin-icon" /> {mobileNumberFmt}
            </p>
            </a>
            </p>
            <p>
            <a href={linkedinLink} target="_blank" rel="noopener noreferrer" className="button"><p class="button-content">
            <FaLinkedin className="linkedin-icon" /> Linkedin
            </p>
            </a>
            </p>
            {/* <p>
            <a href={linkedinLink} target="_blank" rel="noopener noreferrer" className="whatsapp-link">
            <strong><FaLinkedin className="linkedin-icon" /> Linkedin</strong>
            </a>
            </p> */}
            {/* <p>
            <a href={webSiteLink} target="_blank" rel="noopener noreferrer" className="whatsapp-link">
            <strong><FaLink className="website-icon" /> {webSiteLink}</strong>
            </a>
            </p> */}
            <p>
            <a href={webSiteLink} target="_blank" rel="noopener noreferrer" className="button"><p class="button-content">
            <FaLink className="linkedin-icon" /> {webSiteLink}
            </p>
            </a>
            </p>
          </div>
          <p>
          <Mailto email={email} subject="Olá! Gostaria de marcar uma consulta" body="Olá gostaria de marcar uma consulta">
            {email}
          </Mailto>
          </p>
          <a href={linkCalendly} target="_blank" rel="noopener noreferrer" className="button-orange"><p class="button-content">Agendar Consulta</p></a>
        </div>
      </div>
    </div>
  );
}

export default App;
